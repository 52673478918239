#privacy {
    background-color: rgba(0,0,0,0.65);
    color: #808080;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#privacy p {
    max-width: 650px;
    padding-bottom: 5rem;
}

#privacy a {
    color: #0080FF;
}