#videos {
    background-color: rgba(0,0,0,0.65);
    z-index: 2;
    flex: 1;
}

@media only screen and (min-width: 800px) {
    #videos {
        padding-bottom: 15rem;
    }
}

#videos h2 {
    font-family: 'Punk Kid';
    font-size: 5.6rem;
    color: maroon;
    text-align: center;
}

#videos .video-container {
    max-width: 650px;
    margin: 0 auto;
}

.embed-container { 
    position: relative; 
    padding-bottom: 56.25%; 
    height: 0; 
    overflow: hidden; 
    max-width: 100%; 
} 
    
.embed-container iframe, 
.embed-container object, 
.embed-container embed { 
    position: absolute; 
    top: 0; 
    left: 0; 
    width: 100%; 
    height: 100%; 
}