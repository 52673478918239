@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');

@font-face {
    font-family: 'Antara Distance';
    src: url('./fonts/AntaraDistance.ttf');
}

@font-face {
    font-family: 'Punk Kid';
    src: url('./fonts/punk\ kid.ttf');
}

html {
    font-size: 62.5%;
}

body {
    font-size: 1.6rem;
    margin: 0;
}

@media only screen and (min-width: 1150px) {
    .container {
        background-position: center 0%;
    }
}