div.social {
    width: 100%;
}

.social ul {
    list-style: none;
    display: flex;
    justify-content: flex-end;
    border-bottom: 1px solid #808080;
    margin-bottom: 0;
}

.social li {
    padding: .5rem 1rem;
    border-right: 1px solid #808080;
}

.social a {
    color: #0080FF;
    text-decoration: none;
}