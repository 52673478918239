.carousel-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(0,0,0,0.65);
    padding-bottom: 15rem;
    z-index: 2;
}

.carousel {
    width: 75%;
    max-width: 50rem;
    display: flex;
}

.carousel img {
    max-width: 100%;
}

.carousel a {
    outline: none;
}

.left-button,
.right-button {
    color: white;
    font-size: 2.4rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: rgba(255,255,255,0);
    padding: 0 1rem;
}

/* .left-button:hover,
.right-button:hover {
    background-color: rgba(255,255,255,0.2);
} */

/* .left-button {
    position: relative;
    left: 3rem;
}

.right-button {
    position: relative;
    right: 3rem;
} */

.carousel-wrapper h3 {
    color: #808080;
    font-family: 'Oswald', sans-serif;
    font-size: 3.2rem;
}