@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap);
div.social {
    width: 100%;
}

.social ul {
    list-style: none;
    display: flex;
    justify-content: flex-end;
    border-bottom: 1px solid #808080;
    margin-bottom: 0;
}

.social li {
    padding: .5rem 1rem;
    border-right: 1px solid #808080;
}

.social a {
    color: #0080FF;
    text-decoration: none;
}
nav {
    font-family: 'Oswald', sans-serif;
    font-size: 1.8rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #808080;
}

nav ul {
    list-style: none;
    display: flex;
    height: 1.5rem;
    padding: 0;
    margin: 2rem auto;
}

nav li {
    padding: 0 1rem;
    border-right: 1px solid #808080;
    display: flex;
    align-items: center;
    height: 1.5rem;
}

nav a {
    color: #808080;
    text-decoration: none;
}

.last-link {
    border: none;
}

.name {
    font-family: 'Antara Distance';
    color: #800000;
    margin: 4rem 0;
    list-style: none;
    padding: 0;
    text-align: center;
}

.name h1 {
    font-size: 5.6rem;
    font-weight: normal;
    margin: 0;
}

.name li {
    padding-top: .75rem;
}

.coming-soon {
    font-family: 'Oswald', sans-serif;
    color: #808080;
    text-decoration: underline;
    display: inline-block;
    border: none;
    font-size: 1.2rem;
    position: relative;
    bottom: 20px;
    width: 0px;
    white-space: nowrap;
    padding: 0;
}
header {
    background-color: rgba(0,0,0,0.65);
    padding: 0;
    position: relative;
}
.carousel-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(0,0,0,0.65);
    padding-bottom: 15rem;
    z-index: 2;
}

.carousel {
    width: 75%;
    max-width: 50rem;
    display: flex;
}

.carousel img {
    max-width: 100%;
}

.carousel a {
    outline: none;
}

.left-button,
.right-button {
    color: white;
    font-size: 2.4rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: rgba(255,255,255,0);
    padding: 0 1rem;
}

/* .left-button:hover,
.right-button:hover {
    background-color: rgba(255,255,255,0.2);
} */

/* .left-button {
    position: relative;
    left: 3rem;
}

.right-button {
    position: relative;
    right: 3rem;
} */

.carousel-wrapper h3 {
    color: #808080;
    font-family: 'Oswald', sans-serif;
    font-size: 3.2rem;
}
#videos {
    background-color: rgba(0,0,0,0.65);
    z-index: 2;
    flex: 1 1;
}

@media only screen and (min-width: 800px) {
    #videos {
        padding-bottom: 15rem;
    }
}

#videos h2 {
    font-family: 'Punk Kid';
    font-size: 5.6rem;
    color: maroon;
    text-align: center;
}

#videos .video-container {
    max-width: 650px;
    margin: 0 auto;
}

.embed-container { 
    position: relative; 
    padding-bottom: 56.25%; 
    height: 0; 
    overflow: hidden; 
    max-width: 100%; 
} 
    
.embed-container iframe, 
.embed-container object, 
.embed-container embed { 
    position: absolute; 
    top: 0; 
    left: 0; 
    width: 100%; 
    height: 100%; 
}
#events {
    background-color: rgba(0,0,0,0.65);
    padding-bottom: 10rem;
    text-align: center;
    z-index: 2;
    flex: 1 1;
}

#events h2 {
    font-family: 'Punk Kid';
    font-size: 5.6rem;
    color: maroon;
    text-align: center;
}

.date,
.time-place {
    font-family: 'Oswald', sans-serif;
    color: #808080;
}

.date {
    font-size: 2.5rem;
}

hr {
    width: 55%;
    color: #808080;
    margin: 25px auto;
}

#events img {
    width: 500px;
    max-width: 100%;
}

@media only screen and (min-width: 1150px) {
    hr {
        width: 45%;
    }
}
#pictures {
    background-color: rgba(0,0,0,0.65);
    padding-bottom: 15rem;
    z-index: 2;
}

#pictures h2 {
    font-family: 'Punk Kid';
    font-size: 5.6rem;
    color: maroon;
    text-align: center;
}

#pictures .gallery {
    width: 75%;
    display: grid;
    grid-template-columns: 33% 33% 33%;
    grid-template-rows: 0.5fr 0.5fr 1fr;
    margin: 0 auto;
    overflow: hidden;
}

#pictures img {
    max-width: 100%;
    overflow: hidden;
}

#pictures .modal {
    position: fixed;
    top: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-color: rgba(0,0,0,0.8);
    width: 100vw;
    height: 100vh;
}

@media only screen and (min-width: 650px) {
    #pictures .modal {
        background-size: auto 100%;
    }
}
footer {
    background-color: rgba(0,0,0,0.6);
    text-align: center;
    color: #808080;
    position: fixed;
    bottom: 0;
    width: 100vw;
    padding: 16px 0;
    z-index: 3;
}

footer p {
    max-width: 650px;
    margin: 0 auto;
}

footer .copyright {
    float: left;
}

footer .privacy {
    float: right;
}

footer a {
    color: #808080;
    margin: 0 15px;
}
#privacy {
    background-color: rgba(0,0,0,0.65);
    color: #808080;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#privacy p {
    max-width: 650px;
    padding-bottom: 5rem;
}

#privacy a {
    color: #0080FF;
}
.container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-height: 100vh;
    background-color: rgba(0,0,0,0);
}

#background {
    position: fixed;
    top: 0;
    min-height: 100vh;
    min-width: 100vw;
    background-color: black;
    background-image: url(/static/media/ccbg-med.93870c8f.png);
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: top;
    background-attachment: scroll;
    z-index: 1;
}
@font-face {
    font-family: 'Antara Distance';
    src: url(/static/media/AntaraDistance.c6ec8497.ttf);
}

@font-face {
    font-family: 'Punk Kid';
    src: url("/static/media/punk kid.4c92d2fd.ttf");
}

html {
    font-size: 62.5%;
}

body {
    font-size: 1.6rem;
    margin: 0;
}

@media only screen and (min-width: 1150px) {
    .container {
        background-position: center 0%;
    }
}
