#events {
    background-color: rgba(0,0,0,0.65);
    padding-bottom: 10rem;
    text-align: center;
    z-index: 2;
    flex: 1;
}

#events h2 {
    font-family: 'Punk Kid';
    font-size: 5.6rem;
    color: maroon;
    text-align: center;
}

.date,
.time-place {
    font-family: 'Oswald', sans-serif;
    color: #808080;
}

.date {
    font-size: 2.5rem;
}

hr {
    width: 55%;
    color: #808080;
    margin: 25px auto;
}

#events img {
    width: 500px;
    max-width: 100%;
}

@media only screen and (min-width: 1150px) {
    hr {
        width: 45%;
    }
}