nav {
    font-family: 'Oswald', sans-serif;
    font-size: 1.8rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #808080;
}

nav ul {
    list-style: none;
    display: flex;
    height: 1.5rem;
    padding: 0;
    margin: 2rem auto;
}

nav li {
    padding: 0 1rem;
    border-right: 1px solid #808080;
    display: flex;
    align-items: center;
    height: 1.5rem;
}

nav a {
    color: #808080;
    text-decoration: none;
}

.last-link {
    border: none;
}

.name {
    font-family: 'Antara Distance';
    color: #800000;
    margin: 4rem 0;
    list-style: none;
    padding: 0;
    text-align: center;
}

.name h1 {
    font-size: 5.6rem;
    font-weight: normal;
    margin: 0;
}

.name li {
    padding-top: .75rem;
}

.coming-soon {
    font-family: 'Oswald', sans-serif;
    color: #808080;
    text-decoration: underline;
    display: inline-block;
    border: none;
    font-size: 1.2rem;
    position: relative;
    bottom: 20px;
    width: 0px;
    white-space: nowrap;
    padding: 0;
}