footer {
    background-color: rgba(0,0,0,0.6);
    text-align: center;
    color: #808080;
    position: fixed;
    bottom: 0;
    width: 100vw;
    padding: 16px 0;
    z-index: 3;
}

footer p {
    max-width: 650px;
    margin: 0 auto;
}

footer .copyright {
    float: left;
}

footer .privacy {
    float: right;
}

footer a {
    color: #808080;
    margin: 0 15px;
}