#pictures {
    background-color: rgba(0,0,0,0.65);
    padding-bottom: 15rem;
    z-index: 2;
}

#pictures h2 {
    font-family: 'Punk Kid';
    font-size: 5.6rem;
    color: maroon;
    text-align: center;
}

#pictures .gallery {
    width: 75%;
    display: grid;
    grid-template-columns: 33% 33% 33%;
    grid-template-rows: 0.5fr 0.5fr 1fr;
    margin: 0 auto;
    overflow: hidden;
}

#pictures img {
    max-width: 100%;
    overflow: hidden;
}

#pictures .modal {
    position: fixed;
    top: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-color: rgba(0,0,0,0.8);
    width: 100vw;
    height: 100vh;
}

@media only screen and (min-width: 650px) {
    #pictures .modal {
        background-size: auto 100%;
    }
}