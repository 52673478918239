.container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-height: 100vh;
    background-color: rgba(0,0,0,0);
}

#background {
    position: fixed;
    top: 0;
    min-height: 100vh;
    min-width: 100vw;
    background-color: black;
    background-image: url('./images/ccbg-med.png');
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: top;
    background-attachment: scroll;
    z-index: 1;
}